import './App.css';
import NavBar from './components/navbar';
import Footer from './components/footer';
import Loading from './components/loading';
import RoutingContent from './containers/routing';
import { useAuth0 } from '@auth0/auth0-react';
import { initializeGTag } from './components/utils/gtag';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { initializeCookieBot } from './components/utils/cookiebot';
import ScrollToTop from './components/scroll-to-top';


function App() {
  const { isLoading } = useAuth0(); // Auth0 loading state
  const location = useLocation();  // Detecteer routewijzigingen
  const trackingId = 'G-CXKW7CK45P';        // Vervang met jouw Google Analytics tracking ID
  const CBID = 'e5d3d54c-df82-492f-9a40-64b4e9b267c3' // '2f91df37-9b3b-431f-ad8b-4a3dc9f159f9'

  useEffect(() => {
    // Initializeer Google Tag bij het laden van de app
    initializeGTag(trackingId);
    initializeCookieBot(CBID)
  }, []);

  useEffect(() => {
    // Stuur een pageview naar Google Analytics bij elke routewijziging
    if (window.gtag) {
      window.gtag('config', trackingId, { page_path: location.pathname });
    }
  }, [location]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <NavBar />
      <ScrollToTop />
      <RoutingContent />
      <Footer />
    </>
  );
}

export default App;
