import React, { Component } from 'react';
import Section from '../components/sections/section';

class History extends Component {
    render() {
        return (
            <Section 
                title="Geschiedenis" 
                fill={true} 
                accentDark={true}
                image={`${process.env.PUBLIC_URL}/img/sections/2planes_airport.jpg`}
                overlayClass="" 
                blur={true}
                style={{ 
                    position: "relative", 
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/sections/2planes_airport.jpg)`,
                    backgroundSize: "cover", 
                    backgroundPosition: "center"
                }}
            >
                {/* Donkere overlay over de afbeelding, niet de tekst */}
                <div 
                    style={{ 
                        position: "absolute", 
                        top: 0, 
                        left: 0, 
                        width: "100%", 
                        height: "100%", 
                        background: "rgba(0, 0, 0, 0.5)",  // Pas 0.5 aan voor een donkerdere overlay
                        zIndex: 0 
                    }} 
                />
                <div className="section clearfix" id="top" style={{ position: "relative", zIndex: 1 }}>
                    <div className="container" style={{ position: "relative", zIndex: 2 }}>

                        <div className="row">
                            <div className="col-sm-12">
                                <p>
                                Vansteelandt bvba werd in januari 1994 opgericht als landmetersbureau en begon haar activiteiten in de ouderlijke woning van de 2 broers Tom (+) en Bert in Zomergem. De eerste jaren werden gekarakteriseerd door opmetingen voor studiebureaus en het nemen van boorstalen voor Aquafin. Het bedrijf groeide gestaag en kreeg al snel opdrachten van belangrijke spelers zoals Aminal, de Waterwegen, Fluxys en Elia. Daarnaast werd de afdeling Planologie opgericht, wat het dienstenaanbod verder uitbreidde.
                                </p>
                                <p>
                            	In 1997 zette het bedrijf een belangrijke stap door mee in te stappen in het dataverwerkingsbedrijf Paradigm in India, waarmee de deur werd geopend voor grotere opdrachten en een verdere internationalisering van de activiteiten. Deze stap vormde de basis voor het data verwerken en karteren, die uiteindelijk de groei heeft ondersteund die het bedrijf in de komende jaren zou doormaken.
                                </p>
                                <p>
                                De focus op technologische vooruitgang leidde tot een aantal belangrijke initiatieven. Zo werd er samengewerkt met Fugro voor het gebruik van het FLIMAP-systeem, een innovatieve technologie waarbij opmetingen werden uitgevoerd vanuit een helikopter. Er werd een afdeling “Geovisat” opgestart binnen Vansteelandt BV, dit in samenwerking met een Canadees bedrijf (AMS). Deze afdeling was gespecialiseerd in Mobile Mapping. Daarnaast werd een 3D scanner geïntroduceerd voor het digitaliseren van gebouwen.
                                </p>
                                <p>
                                In 2004 werd de afdeling Planologie afgesplitst en werd het planologisch bureau Adoplan opgericht. Dit was een strategische zet die de groei van het bedrijf verder ondersteunde. Door de jaren heen verhuisde het bedrijf verschillende keren naar grotere panden om de groei te accommoderen. Na vestigingen in Eke en op de E3 laan in Deinze, vond het bedrijf in 2011 zijn huidige locatie op de Vaartlaan 28 in Deinze.
                                </p>
                                <p>
                                Het bedrijf bleef zich onderscheiden door zijn honger naar innovatie. Dit bleek uit de ontwikkeling van de Multicam-viewer, een aerial survey sensor die werd ingebouwd in een speciaal vliegtuig en die het mogelijk maakte om gans Vlaanderen in detail in kaart te brengen. Daarnaast werd er geïnvesteerd in toenmalige geavanceerde technologieën zoals de Leica-scanner en werden er nieuwe vliegtuigen aangeschaft voor opmetingsvluchten met state of the art opname-sensoren.
                                </p>
                                <p>
                                In de latere jaren werd de samenwerking met andere bedrijven versterkt, wat leidde tot deelname van deze bedrijven in het bedrijfskapitaal. De huidige participatie van GEOxyz borgt het verdere doorgroeien van Vansteelandt BV door de optimalisering van beide bedrijfswaarden en capaciteiten. In 2024 werd Adoplan overgenomen door Embridge.
                                </p>
                                <p>
                                De laatste grote technologische innovatie is zonder twijfel de ontwikkeling van de eigen Multiview-sensor, die hoogkwalitatieve luchtopnames en data-analyses mogelijk maakt vanuit een fotografische sensor en het volop inzetten van Artificial Intelligence (AI) en machine learning modelling voor de verdieping van de Vansteelandt data insights. 
                                </p>
                                <p>
                                Vandaag de dag is Vansteelandt BV een toonaangevend bedrijf in de landmeetkundige sector, met een sterke focus op technologische innovatie en continue groei. Het bedrijf blijft zich inzetten om nieuwe technieken te ontwikkelen en haar data-insight-aanbod voortdurend te verdiepen en aan te passen aan de stijgende vraag in de markt.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                    
                </Section>
            
            
            /*
            <Section 
            title="Geschiedenis" 
            fill={true}
            accentDark={true}
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/img/overlays/IMG_1964.jpg)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh'  // Zorgt ervoor dat de afbeelding de volledige hoogte van de pagina bedekt
            }}
        >
                <div className="section clearfix" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <p>
                                Vansteelandt bvba werd in januari 1994 opgericht als landmetersbureau en begon haar activiteiten in de ouderlijke woning van de 2 broers Tom (+) en Bert in Zomergem. De eerste jaren werden gekarakteriseerd door opmetingen voor studiebureaus en het nemen van boorstalen voor Aquafin. Het bedrijf groeide gestaag en kreeg al snel opdrachten van belangrijke spelers zoals Aminal, de Waterwegen, Fluxys en Elia. Daarnaast werd de afdeling Planologie opgericht, wat het dienstenaanbod verder uitbreidde.
                                </p>
                                <p>
                                In 1997 zette het bedrijf een belangrijke stap door het oprichten van het dataverwerkingsbedrijf Paradigm in India, waarmee de deur werd geopend voor grotere opdrachten en een verdere internationalisering van de activiteiten. Deze stap vormde de basis voor het data verwerken en karteren, die uiteindelijk de groei heeft ondersteund die het bedrijf in de komende jaren zou doormaken.
                                </p>
                                <p>
                                De focus op technologische vooruitgang leidde tot een aantal belangrijke initiatieven. Zo werd er samengewerkt met Fugro voor het gebruik van het Flymap-systeem, een innovatieve technologie waarbij opmetingen werden uitgevoerd vanuit een helikopter. Er werd een afdeling “Geovisat” opgestart binnen Vansteelandt BV, dit in samenwerking met een Canadees bedrijf (AMS). Deze afdeling was gespecialiseerd in Mobile Mapping. Daarnaast werd een 3D scanner geïntroduceerd voor het digitaliseren van gebouwen.
                                </p>
                                <p>
                                In 2004 werd de afdeling Planologie afgesplitst en werd het planologisch bureau Adoplan opgericht. Dit was een strategische zet die de groei van het bedrijf verder ondersteunde. Door de jaren heen verhuisde het bedrijf verschillende keren naar grotere panden om de groei te accommoderen. Na vestigingen in Eke en op de E3 laan in Deinze, vond het bedrijf in 2011 zijn huidige locatie op de Vaartlaan 28 in Deinze.
                                </p>
                                <p>
                                Het bedrijf bleef zich onderscheiden door zijn honger naar innovatie. Dit bleek uit de ontwikkeling van de Multicam-viewer, een aerial survey sensor die werd ingebouwd in een speciaal vliegtuig en die het mogelijk maakte om gans Vlaanderen in detail in kaart te brengen. Daarnaast werd er geïnvesteerd in toenmalige geavanceerde technologieën zoals de Leica-scanner en werden er nieuwe vliegtuigen aangeschaft voor opmetingsvluchten met state of the art opname-sensoren.
                                </p>
                                <p>
                                In de latere jaren werd de samenwerking met andere bedrijven versterkt, wat leidde tot deelname van deze bedrijven in het bedrijfskapitaal. De huidige participatie van GEOxyz borgt het verdere doorgroeien van Vansteelandt BV door de optimalisering van beide bedrijfswaarden en capaciteiten. In 2024 werd Adoplan overgenomen door Embridge.
                                </p>
                                <p>
                                De laatste grote technologische innovatie is zonder twijfel de ontwikkeling van de eigen Multiview-sensor, die hoogkwalitatieve luchtopnames en data-analyses mogelijk maakt vanuit een fotografische sensor en het volop inzetten van Artificial Intelligence (AI) en machine learning modelling voor de verdieping van de Vansteelandt data insights. 
                                </p>
                                <p>
                                Vandaag de dag is Vansteelandt BV een toonaangevend bedrijf in de landmeetkundige sector, met een sterke focus op technologische innovatie en continue groei. Het bedrijf blijft zich inzetten om nieuwe technieken te ontwikkelen en haar data-insight-aanbod voortdurend te verdiepen en aan te passen aan de stijgende vraag in de markt.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>*/
        );
    }
}

export default History;
