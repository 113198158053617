import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router'
import { HashLink as Link } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';
import { ROUTES, LAYOUT } from '../consts/index';
import styles from './navbar.module.css'; 
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from './authentication/LogoutButton';
import LoginButton from './authentication/LoginButton ';
// import $ from 'jquery';

class NavBar extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
      }

    // componentDidMount() {
        // $('.navbar-collapse ul li a:not(.dropdown-toggle), .navbar-brand').on('click touchstart', function () {
        //     $('.navbar-toggler').not('.collapsed').click();
        // });
    // }

    render() {
        const { location } = this.props
        let links;
        switch(location.pathname) {
            case ROUTES.serviceDetailOrder:
                links = [
                    {path: ROUTES.serviceDetailOrderWhy, name: 'Waarom'},
                    {path: ROUTES.serviceDetailOrderWhat, name: 'Wat'},
                    {path: ROUTES.serviceDetailOrderPrice, name: 'Kostprijs'},
                    {path: ROUTES.serviceDetailOrderOrder, name: 'Bestel'},
                ];
                break;
            default:
                links = LAYOUT;
        }

        const lis = links.map((l, i) => {
            if (l.children) {
                return (
                    <li className="nav-item dropdown" key={i}>
                        <Link 
                            className="nav-link dropdown-toggle" 
                            to={l.path} 
                            id={`navbarDropdown-${i}`} 
                            role="button" 
                            data-toggle="dropdown" 
                            aria-haspopup="true" 
                            aria-expanded="false"
                        >
                            {l.name}
                        </Link>
                        <div className="dropdown-menu" aria-labelledby={`navbarDropdown-${i}`}>
                            {l.children.map((sub, j) => (
                                sub.path.startsWith("/#") ? (
                                    // Gebruik HashLink voor interne scroll-links
                                    <Link key={j} className="dropdown-item" to={sub.path} smooth>
                                        {sub.name}
                                    </Link>
                                ) : (
                                    // Gebruik gewone Link voor pagina's
                                    <Link key={j} className="dropdown-item" to={sub.path}>
                                        {sub.name}
                                    </Link>
                                )
                            ))}
                        </div>
                    </li>
                );
            }
            return (
                <li className="nav-item" key={i}>
                    <Link className="nav-link waves-effect waves-light" to={l.path}>{l.name}</Link>
                </li>
            );
        });
        
        

        return (
        <nav className={`navbar navbar-expand-lg navbar-dark sticky-top scrolling-navbar top-nav-collapse ${styles["bg-blue-dark"]}`} id="navbarMenu">
            <div className="container">
                <Link className="navbar-brand" to={ROUTES.home.header}>
                    <img src={`${process.env.PUBLIC_URL}/img/logo/logo_vansteelandt.png`} alt="Vansteelandt Smart Survey Services" height="42px"/>
                </Link>
                <button className="navbar-toggler collapsed nav-link" type="button" data-toggle="collapse" data-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fas fa-bars"></span>
                </button>

                <div className="navbar-collapse collapse" id="navbarToggle">
                    <ul className="navbar-nav mr-auto">
                        { lis }
                    </ul>

                </div>
            </div>
        </nav>
    )};
}


const AdaptiveNavBar = withRouter(NavBar)

export default AdaptiveNavBar
